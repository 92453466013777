<template>
  <v-text-field v-model="value" type="time" :label="name" v-on:change="setValue"></v-text-field>
</template>

<script>
import moment from "moment";
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["name", "callbackSelect", "formKey", "value"],
  data: () => ({
    time: moment().format("HH:mm"),
    menu2: false,
    modal2: false,
  }),

  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
    setValue(val){
       this.callbackSelect(val, this.formKey);
    }
  },
  beforeMount() {},
};
</script>