<template>
  <v-text-field
  v-model="inputVal"
    :label='name'
    :rules="name == 'Title'? [v => !!v || 'Title is required']:[]"
    :type="type"
  ></v-text-field>
</template>

<script>
export default {
   props: ["value","name","type"],
    data: () => ({
    name: null,
   
  }),
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>