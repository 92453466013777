<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title v-if="action=='add'">New News</v-card-title>
        <v-card-title v-else>Edit News</v-card-title>
        <v-card-text>
          <template>
            <FormInput
              type="text"
              name="Title"
              :value="form['translatableTitle']"
              @input="form['translatableTitle'] = $event"
            />
          </template>
          <template>
            <FormInput
              type="text"
              name="Body"
              :value="form['translatableBody']"
              @input="form['translatableBody'] = $event"
            />
          </template>
          <template>
            <FormInput
              type="text"
              name="Action Url"
              :value="form['actionUrl']"
              @input="form['actionUrl'] = $event"
            />
          </template>
          <template>
            <FormInput
              type="text"
              name="Action"
              :value="form['translatableAction']"
              @input="form['translatableAction'] = $event"
            />
          </template>
          <template>
            <FormInput
              type="number"
              name="Priority"
              :value="form['priority']"
              @input="form['priority'] = $event"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateStart"
              name="Start Date"
              :value="form.dateStart"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeStart"
              name="Start Time"
              :value="form.timeStart"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateEnd"
              name="End Date"
              :value="form.dateEnd"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeEnd"
              name="End Time"
              :value="form.timeEnd"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isPublished']"
              @input="form['isPublished'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/News/FormInput";
import FormDate from "@/components/Moderator/News/FormDate";
import FormTime from "@/components/Moderator/News/FormTime";
import FormRadio from "@/components/Moderator/News/FormRadio";

import moment from "moment";
import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
    FormInput,
    FormDate,
    FormTime,
    FormRadio,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    templates: [
      {
        name: "New Clothing Set",
        key: "shop",
      },
      {
        name: "New Event",
        key: "event",
      },
      {
        name: "New Feature",
        key: "feature",
      },
    ],
    selectedTemplate:'shop',
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      translatableTitle:
        '{"en":"New Clothing Set!","zh":"新服装套装!","ms":"Set Pakaian Baru!"}',
      translatableBody:
        '{"en":"Santa Clothing","zh":"圣诞套装","ms":"Set Santa"}',
      actionUrl: "/shop?page=9",
      translatableAction: '{"en":"Try On","zh":"试穿","ms":"Cuba Pakai"}',
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(5, "days").format("YYYY-MM-DD"),
      timestampStart: moment().toISOString(),
      timestampEnd: moment().toISOString(),
      joinedDate: moment().toISOString(),
      timeStart: "08:00",
      timeEnd: "23:00",
      priority: 1,
      isPublished: true,
      templateKey:'shop'
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    entertName(name) {
      this.form.name = name;
    },
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The news start date and time should be less than the news end date and time."
          );
        } else {
          this.submit();
        }
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    selectTemplate(template){
      this.selectedTemplate = template.key
    }
    //EOC
  },
  mounted(){
    if(this.action == 'edit'){
      this.form.id = this.$route.params.id;
    this.form.translatableTitle = this.self.translatableTitle;
    this.form.translatableBody = this.self.translatableBody;
    this.form.actionUrl = this.self.actionUrl;
    this.form.translatableAction = this.self.translatableAction;
    this.form.dateStart = moment(this.self.timestampStart).format("YYYY-MM-DD");
    this.form.dateEnd = moment(this.self.timestampEnd).format("YYYY-MM-DD");
    this.form.timeStart = moment(this.self.timestampStart).format("HH:mm");
    this.form.timeEnd = moment(this.self.timestampEnd).format("HH:mm");
    this.form.priority = this.self.priority;
    this.form.isPublished = this.self.isPublished;
    }
  }
};
</script>

<style>
</style>